body.login {
    background-image: url(../img/bg.jpg);
    background-size: cover;
}
.navbar-brand img {
    height: auto;
    max-width: 230px;
}

.navbar-brand {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
}

.sidebar-darks {
    background-color: #ad1457;
}

.table td {
    vertical-align: top;
}

/* Pagination */
.pagination {
    margin: 10px auto;
    float: right;
}
.pagination-sm .page-link {
    font-size: 12px;
}
.page-item.active .page-link {
    color: #fff;
    background-color: #e91e63;
}
.pagination > li.active a, .pagination > li.active a:hover, .pagination > li.active a:focus, .pagination > li.active span, .pagination > li.active span:hover, .pagination > li.active span:focus{
    color: #fff;
    background-color: #e91e63;
}
.pagination > li a:hover, .pagination > li a:focus{
    color: #fff;
    background-color: #e91e63;
}
.pagination li {
    margin-left: 2px;
}
.pagination li a {
    font-size: 11px;
    position: relative;
    display: block;
    padding: 0.4rem 0.6rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #777;
    background-color: #fff;
    border: 1px solid #ddd;
}
.pagination li a:hover {
    text-decoration: none;
}
.position-relative {
    position: relative;
}
.total-results {
    margin: 10px auto;
}
.text-danger [ng-message]{
    font-size: 82%;
}
.uniform-checker {
    display: inline-block;
}
.layout-boxed {
    box-shadow: none;
}
.logo-smart {
    max-width: 240px;
}
.logo-fullcertificate {
    max-width: 130px;
}
.tabla-planes {

}
.tabla-planes td {
    text-align: center;
    max-width: 150px;
}
.wizard>.actions>ul {
    text-align: center;
}
.caja-condiciones {
    max-height: 250px;
    overflow-y: auto;
    padding: 10px;
    border: 1px solid #9d9d9d;
}